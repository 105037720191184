// App.tsx
import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Tabs, Tab, Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import CollisionRiskDashboard from './components/CollisionRisk/CollisionRiskDashboard';
import UsersPage from './components/Users/UsersPage';
import ExceptionsDashboard from './components/ExceptionsDashboard/ExceptionsDashboard';
import CustomerConfigurationPage from './components/CustomerConfiguration/CustomerConfigurationPage';
import DeviceStatusTable from './components/DeviceStatus/DeviceStatusTable';
import RequireAuth from './RequireAuth';
import { AppContext } from './AppContext';
import AuthSync from './AuthSync';

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, logout, isLoading } = useAuth0();
  const { setIsAuthenticated, user: appUser } = useContext(AppContext);

  // State to track if redirection has already occurred
  const [hasRedirected, setHasRedirected] = useState(false);

  // Sync Auth0 authentication status with your AppContext
  useEffect(() => {
    if (!isLoading) {
      setIsAuthenticated(isAuthenticated);
    }
  }, [isAuthenticated, isLoading, setIsAuthenticated]);

  // Conditional redirection based on user permissions
  useEffect(() => {
    if (!isLoading && isAuthenticated && appUser && !hasRedirected) {
      let defaultPath = '/exceptions-dashboard';

      // If the user has 'ViewCollisionRiskDashboard' permission, set defaultPath to '/'
      if (appUser.permissions?.includes('ViewCollisionRiskDashboard')) {
        defaultPath = '/';
      }

      // Only redirect if the user is on the root path to prevent overriding navigation
      if (location.pathname === '/' || location.pathname === window.location.origin) {
        navigate(defaultPath, { replace: true });
        setHasRedirected(true); // Prevent future redirects
      }
    }
  }, [isLoading, isAuthenticated, appUser, location.pathname, navigate, hasRedirected]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    logout({
      logoutParams: { returnTo: window.location.origin }
    });
  };

  // Determine available tab values based on permissions
  const availableTabValues: string[] = [];
  if (appUser?.permissions?.includes('ViewCollisionRiskDashboard')) {
    availableTabValues.push('/');
  }
  if (appUser?.permissions?.includes('ViewExceptionsDashboard')) {
    availableTabValues.push('/exceptions-dashboard');
  }
  if (appUser?.permissions?.includes('ViewDeviceStatusTable')) {
    availableTabValues.push('/device-status-table');
  }
  if (appUser?.permissions?.includes('ViewUsersTable')) {
    availableTabValues.push('/users');
  }
  if (appUser?.permissions?.includes('ViewCustomerConfiguration')) {
    availableTabValues.push('/customer-config');
  }

  // Set the Tabs' value only if it matches one of the available tabs
  const currentTabValue = availableTabValues.includes(location.pathname) ? location.pathname : false;

  return (
    <>
      <AuthSync />
      {isAuthenticated && appUser && (
        <AppBar position="static">
          <Toolbar>
            <Tabs
              value={currentTabValue}
              onChange={handleChange}
              textColor="inherit"
              sx={{
                '& .MuiTabs-indicator': {
                  backgroundColor: 'white',
                },
                flexGrow: 1,
              }}
            >
              {appUser.permissions?.includes('ViewCollisionRiskDashboard') && (
                <Tab label="Collision Risk Dashboard" value="/" />
              )}
              {appUser.permissions?.includes('ViewExceptionsDashboard') && (
                <Tab label="Exceptions Dashboard" value="/exceptions-dashboard" />
              )}
              {appUser.permissions?.includes('ViewDeviceStatusTable') && (
                <Tab label="Device Status Table" value="/device-status-table" />
              )}
              {appUser.permissions?.includes('ViewUsersTable') && (
                <Tab label="Users" value="/users" />
              )}
              {appUser.permissions?.includes('ViewCustomerConfiguration') && (
                <Tab label="Customer Configuration" value="/customer-config" />
              )}
            </Tabs>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      )}
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth permission='ViewCollisionRiskDashboard'>
              <CollisionRiskDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/exceptions-dashboard"
          element={
            <RequireAuth permission='ViewExceptionsDashboard'>
              <ExceptionsDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/device-status-table"
          element={
            <RequireAuth permission='ViewDeviceStatusTable'>
              <DeviceStatusTable />
            </RequireAuth>
          }
        />
        <Route
          path="/users"
          element={
            <RequireAuth permission='ViewUsersTable'>
              <UsersPage />
            </RequireAuth>
          }
        />
        <Route
          path="/customer-config"
          element={
            <RequireAuth permission='ViewCustomerConfiguration'>
              <CustomerConfigurationPage />
            </RequireAuth>
          }
        />
      </Routes>
    </>
  );
};

export default App;
