import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Popover,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Calendar, DayRange, DayValue } from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import axios from 'axios';
import { format, differenceInCalendarDays, parseISO } from 'date-fns';
import { getAccounts } from './ApiCalls';

// Recharts
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  ComposedChart,
  Line,
  TooltipProps
} from 'recharts';

/** Types **/
interface Account {
  database: string;
  username: string;
  common_name: string;
  Active: boolean;
}

interface ExceptionWithMiles {
  name: string;
  count: number;
  miles: number;
}

interface ExceptionRecord {
  db_name: string;
  miles: string;
  datetime: string;
  num_harsh_acceleration_exceptions: string;
  num_harsh_cornering_exceptions: string;
  num_harsh_braking_exceptions: string;
  num_seatbelt_exceptions: string;
  num_speeding_exceptions: string;
  device_count: string;
}

interface ChartDataPoint {
  index: number; // Represents the bar count on the X-axis
  label: string; // 3-letter month for X-axis
  tooltipLabel: string; // Full date range for tooltip
  count: number; // Exceptions count per 1000 miles
}

/** Custom Tooltip **/
const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Box sx={{ backgroundColor: 'white', border: '1px solid #ccc', p: 1 }}>
        <Typography variant="body2">{`Date: ${data.tooltipLabel}`}</Typography>
        <Typography variant="body2">{`Exceptions per 1000mi: ${data.count}`}</Typography>
      </Box>
    );
  }

  return null;
};

/** Date Filter Types **/
type DateFilterType = 'all' | '30' | '90' | '360' | 'custom';

/** Convert DayValue -> JS Date */
function toJSDate(day: DayValue, isEndDate: boolean = false): Date | null {
  if (!day) return null;
  const date = new Date(day.year, day.month - 1, day.day);
  if (isEndDate) {
    date.setHours(23, 59, 59, 999);
  } else {
    date.setHours(0, 0, 0, 0);
  }
  return date;
}

/** Display range in the UI, e.g. "Jul 05, 2025" */
function formatDayRange(day: DayValue): string {
  if (!day) return '';
  const date = new Date(day.year, day.month - 1, day.day);
  return format(date, 'MMM dd, yyyy');
}

/** Main Component **/
const ExceptionsDashboard: React.FC = () => {
  /** 1) Accounts */
  const [activeAccounts, setActiveAccounts] = useState<Account[]>([]);
  const [inactiveAccounts, setInactiveAccounts] = useState<Account[]>([]);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);
  const [showInactive, setShowInactive] = useState<boolean>(false);

  /** 2) Selected account */
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  /** 3) Date-range filter */
  const [daysFilter, setDaysFilter] = useState<DateFilterType>('30');
  const [customDateRange, setCustomDateRange] = useState<DayRange>({ from: null, to: null });
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLElement | null>(null);
  const calendarOpen = Boolean(calendarAnchorEl);

  /** 4) Chart data sets */
  const [chartDataSpeeding, setChartDataSpeeding] = useState<ChartDataPoint[]>([]);
  const [chartDataSeatbelt, setChartDataSeatbelt] = useState<ChartDataPoint[]>([]);
  const [chartDataCornering, setChartDataCornering] = useState<ChartDataPoint[]>([]);
  const [chartDataAcceleration, setChartDataAcceleration] = useState<ChartDataPoint[]>([]);
  const [chartDataBraking, setChartDataBraking] = useState<ChartDataPoint[]>([]);

  /** 5) Additional info */
  const [deviceCount, setDeviceCount] = useState('');
  const [activeFrom, setActiveFrom] = useState('');
  const [activeTo, setActiveTo] = useState('');
  const [totalMiles, setTotalMiles] = useState(0);

  /** 6) Are we loading chart data? */
  const [isLoadingCharts, setIsLoadingCharts] = useState(false);

  /**
   * On mount, fetch accounts + total miles
   */
  useEffect(() => {
    const fetchAccountsAndMiles = async () => {
      try {
        // 1) Load accounts
        const accountsFromDB: Account[] = await getAccounts();
        const active = accountsFromDB.filter((acct) => acct.Active);
        const inactive = accountsFromDB.filter((acct) => !acct.Active);

        // Insert "All Accounts"
        active.push({
          database: 'all_accounts',
          username: '',
          common_name: 'All Accounts',
          Active: true,
        });

        const sortedActive = active.sort((a, b) =>
          a.common_name.toUpperCase() < b.common_name.toUpperCase() ? -1 : 1
        );
        const sortedInactive = inactive.sort((a, b) =>
          a.common_name.toUpperCase() < b.common_name.toUpperCase() ? -1 : 1
        );

        setActiveAccounts(sortedActive);
        setInactiveAccounts(sortedInactive);

        // 2) Load total miles
        const response = await axios.get(
          'https://v4dzu86w87.execute-api.us-east-1.amazonaws.com/dev//total-miles'
        );
        const responseData = JSON.parse(response.data.body);
        setTotalMiles(responseData.totalMiles > 0 ? responseData.totalMiles : 0);
      } catch (error) {
        console.error('Error fetching accounts/miles:', error);
        setTotalMiles(0);
      } finally {
        setIsLoadingAccounts(false);
      }
    };

    fetchAccountsAndMiles();
  }, []);

  /**
   * fetchAndProcessData
   */
  const fetchAndProcessData = useCallback(
    async ({
      account,
      filter,
      custom,
    }: {
      account: Account;
      filter: DateFilterType;
      custom: { from: Date | null; to: Date | null } | null;
    }) => {
      let url = '';
      if (account.database === 'all_accounts') {
        url = 'https://m4pzul24f3ckddfuqhypbow5wu0slppy.lambda-url.us-east-1.on.aws/';
      } else {
        url =
          'https://v4dzu86w87.execute-api.us-east-1.amazonaws.com/dev//normalized-exceptions?db_name=' +
          account.database;
      }

      try {
        const response = await axios.get(url);
        console.log(response);
        const rawData: ExceptionRecord[] = response.data || [];

        // Sort ascending by datetime
        rawData.sort(
          (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
        );

        if (rawData.length) {
          setActiveFrom(rawData[0].datetime);
          setActiveTo(rawData[rawData.length - 1].datetime);
        } else {
          setActiveFrom('');
          setActiveTo('');
        }

        return processExceptionData(rawData, filter, custom);
      } catch (err) {
        console.error('Error fetching exceptions:', err);
        setActiveFrom('');
        setActiveTo('');
        return {
          speed: [],
          seatbelt: [],
          corner: [],
          accel: [],
          brake: [],
          deviceCount: '',
        };
      }
    },
    []
  );

  /**
   * processExceptionData
   */
  function processExceptionData(
    rawData: ExceptionRecord[],
    filter: DateFilterType,
    custom: { from: Date | null; to: Date | null } | null
  ) {
    // 1) Determine startDate and endDate based on the filter
    let startDate: Date | null = null;
    let endDate: Date | null = new Date();
    const now = new Date();

    if (!rawData.length) {
      // Return empty if no data
      return {
        speed: [],
        seatbelt: [],
        corner: [],
        accel: [],
        brake: [],
        deviceCount: '',
      };
    }

    switch (filter) {
      case '30':
        startDate = new Date(now.getTime() - 29 * 86400000); // Last 30 days
        break;
      case '90':
        startDate = new Date(now.getTime() - 89 * 86400000); // Last 90 days
        break;
      case '360':
        startDate = new Date(now.getTime() - 359 * 86400000); // Last 360 days
        break;
      case 'custom':
        startDate = custom?.from || null;
        endDate = custom?.to || new Date();
        break;
      case 'all':
        // Use the earliest and latest dates from rawData
        startDate = new Date(rawData[0].datetime);
        endDate = new Date(rawData[rawData.length - 1].datetime);
        break;
    }

    // 2) Filter rawData based on startDate and endDate
    const filtered = rawData.filter((rec) => {
      const d = new Date(rec.datetime);
      if (!startDate) return true;
      return d >= startDate && d <= endDate!;
    });

    if (!filtered.length) {
      // Return empty if no data
      return {
        speed: [],
        seatbelt: [],
        corner: [],
        accel: [],
        brake: [],
        deviceCount: '',
      };
    }

    // deviceCount from the last record
    const deviceCount = filtered[filtered.length - 1].device_count || '';

    // 3) Build a dayMap: date => miles + exceptions
    const dayMap: Record<
      string,
      {
        miles: number;
        speed: number;
        seatbelt: number;
        corner: number;
        accel: number;
        brake: number;
      }
    > = {};

    filtered.forEach((r) => {
      const key = format(new Date(r.datetime), 'yyyy-MM-dd');
      if (!dayMap[key]) {
        dayMap[key] = {
          miles: 0,
          speed: 0,
          seatbelt: 0,
          corner: 0,
          accel: 0,
          brake: 0,
        };
      }
      dayMap[key].miles += parseFloat(r.miles) || 0;
      dayMap[key].speed += parseFloat(r.num_speeding_exceptions) || 0;
      dayMap[key].seatbelt += parseFloat(r.num_seatbelt_exceptions) || 0;
      dayMap[key].corner += parseFloat(r.num_harsh_cornering_exceptions) || 0;
      dayMap[key].accel += parseFloat(r.num_harsh_acceleration_exceptions) || 0;
      dayMap[key].brake += parseFloat(r.num_harsh_braking_exceptions) || 0;
    });

    // **Ensure All Dates Are Represented**
    if (startDate && endDate) {
      const allDates: string[] = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const key = format(currentDate, 'yyyy-MM-dd');
        allDates.push(key);
        if (!dayMap[key]) {
          dayMap[key] = {
            miles: 0,
            speed: 0,
            seatbelt: 0,
            corner: 0,
            accel: 0,
            brake: 0,
          };
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    let sortedDays = Object.keys(dayMap).sort();
    // 4) Build daily arrays with counts and miles
    const speedDaily: ExceptionWithMiles[] = [];
    const seatbeltDaily: ExceptionWithMiles[] = [];
    const cornerDaily: ExceptionWithMiles[] = [];
    const accelDaily: ExceptionWithMiles[] = [];
    const brakeDaily: ExceptionWithMiles[] = [];

    sortedDays.forEach((day) => {
      const group = dayMap[day];
      const miles = group.miles || 0;

      speedDaily.push({ name: day, count: group.speed, miles });
      seatbeltDaily.push({ name: day, count: group.seatbelt, miles });
      cornerDaily.push({ name: day, count: group.corner, miles });
      accelDaily.push({ name: day, count: group.accel, miles });
      brakeDaily.push({ name: day, count: group.brake, miles });
    });

    // 5) Decide how to group based on the filter
    let speed: ChartDataPoint[] = [];
    let seatbelt: ChartDataPoint[] = [];
    let corner: ChartDataPoint[] = [];
    let accel: ChartDataPoint[] = [];
    let brake: ChartDataPoint[] = [];

    if (filter === '30') {
      // **Use Daily Data for '30' Days**
      speed = mapDailyDataToChartData(speedDaily);
      seatbelt = mapDailyDataToChartData(seatbeltDaily);
      corner = mapDailyDataToChartData(cornerDaily);
      accel = mapDailyDataToChartData(accelDaily);
      brake = mapDailyDataToChartData(brakeDaily);
    } else if (filter === '90') {
      // **Use Enhanced Chunking for '90' Days (Weekly Chunks)**
      speed = chunkAveragedWithMilesLabels(speedDaily, 7);
      seatbelt = chunkAveragedWithMilesLabels(seatbeltDaily, 7);
      corner = chunkAveragedWithMilesLabels(cornerDaily, 7);
      accel = chunkAveragedWithMilesLabels(accelDaily, 7);
      brake = chunkAveragedWithMilesLabels(brakeDaily, 7);
    } else if (filter === '360') {
      // **Use Enhanced Chunking for '360' Days (Monthly Chunks with 'MMM' Labels)**
      speed = chunkAveragedWithMilesLabels(speedDaily, 30, 'MMM');
      seatbelt = chunkAveragedWithMilesLabels(seatbeltDaily, 30, 'MMM');
      corner = chunkAveragedWithMilesLabels(cornerDaily, 30, 'MMM');
      accel = chunkAveragedWithMilesLabels(accelDaily, 30, 'MMM');
      brake = chunkAveragedWithMilesLabels(brakeDaily, 30, 'MMM');
    } else if (filter === 'custom') {
      // **Use Enhanced Chunking for 'Custom' Range**
      speed = groupDataWithMilesForCustomRange(speedDaily, startDate, endDate);
      seatbelt = groupDataWithMilesForCustomRange(seatbeltDaily, startDate, endDate);
      corner = groupDataWithMilesForCustomRange(cornerDaily, startDate, endDate);
      accel = groupDataWithMilesForCustomRange(accelDaily, startDate, endDate);
      brake = groupDataWithMilesForCustomRange(brakeDaily, startDate, endDate);
    } else if (filter === 'all') {
      // **Use Enhanced Chunking for 'All' Range**
      speed = groupDataWithMilesForCustomRange(speedDaily, startDate, endDate);
      seatbelt = groupDataWithMilesForCustomRange(seatbeltDaily, startDate, endDate);
      corner = groupDataWithMilesForCustomRange(cornerDaily, startDate, endDate);
      accel = groupDataWithMilesForCustomRange(accelDaily, startDate, endDate);
      brake = groupDataWithMilesForCustomRange(brakeDaily, startDate, endDate);
    }

    // 6) Return the processed data
    return {
      speed,
      seatbelt,
      corner,
      accel,
      brake,
      deviceCount,
    };
  }

  /** Put final chart data in state */
  function applyProcessedData(proc: any) {
    console.log('Processed Speeding Data:', proc.speed);
    setChartDataSpeeding(proc.speed);
    setChartDataSeatbelt(proc.seatbelt);
    setChartDataCornering(proc.corner);
    setChartDataAcceleration(proc.accel);
    setChartDataBraking(proc.brake);
    setDeviceCount(proc.deviceCount || '');
  }

  /** When user selects an account */
  const handleSelectAccount = async (account: Account) => {
    setSelectedAccount(account);
    setIsLoadingCharts(true);
    try {
      const processed = await fetchAndProcessData({
        account,
        filter: daysFilter,
        custom: null,
      });
      applyProcessedData(processed);
    } finally {
      setIsLoadingCharts(false);
    }
  };

  /** If daysFilter changes and we have an account, refetch (unless custom) */
  useEffect(() => {
    if (!selectedAccount) return;
    if (daysFilter === 'custom') return;
    setIsLoadingCharts(true);
    fetchAndProcessData({ account: selectedAccount, filter: daysFilter, custom: null })
      .then((p) => applyProcessedData(p))
      .finally(() => setIsLoadingCharts(false));
  }, [selectedAccount, daysFilter, fetchAndProcessData]);

  /** If user picks a custom date range */
  useEffect(() => {
    if (!selectedAccount) return;
    if (daysFilter !== 'custom') return;

    if (customDateRange.from && customDateRange.to) {
      setIsLoadingCharts(true);
      fetchAndProcessData({
        account: selectedAccount,
        filter: daysFilter,
        custom: {
          from: toJSDate(customDateRange.from),
          to: toJSDate(customDateRange.to, true),
        },
      })
        .then((p) => applyProcessedData(p))
        .finally(() => setIsLoadingCharts(false));
    }
  }, [selectedAccount, daysFilter, customDateRange, fetchAndProcessData]);

  /** Calendar popover handlers */
  function handleCalendarOpen(e: React.MouseEvent<HTMLElement>) {
    setCalendarAnchorEl(e.currentTarget);
  }
  function handleCalendarClose() {
    setCalendarAnchorEl(null);
  }

  /** If still loading accounts, big spinner */
  if (isLoadingAccounts) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: 4 }}>
        <Box textAlign="center" sx={{ mt: 8 }}>
          <CircularProgress />
          <Typography>Loading accounts...</Typography>
        </Box>
      </Container>
    );
  }

  // Format activeFrom/activeTo
  const formattedActiveFrom = activeFrom ? format(new Date(activeFrom), 'MMM dd, yyyy') : '—';
  const formattedActiveTo = activeTo ? format(new Date(activeTo), 'MMM dd, yyyy') : '—';

  // Show inactive or not
  const displayedAccounts = showInactive ? inactiveAccounts : activeAccounts;

  return (
    <Container maxWidth="lg" sx={{ marginTop: 4 }}>
      {/* Title */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Exceptions Dashboard</Typography>
      </Box>

      {/* Account + Date Range */}
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
        {/* Accounts */}
        <FormControl variant="outlined" sx={{ minWidth: 250 }}>
          <InputLabel>Accounts</InputLabel>
          <Select
            value={selectedAccount?.database || ''}
            label="Accounts"
            onChange={(e) => {
              const val = e.target.value;
              const found = displayedAccounts.find((acct) => acct.database === val);
              if (found) {
                handleSelectAccount(found);
              }
            }}
          >
            {!selectedAccount && (
              <MenuItem value="" disabled>
                (Select an Account)
              </MenuItem>
            )}
            {displayedAccounts.map((acct) => (
              <MenuItem key={acct.database} value={acct.database}>
                {acct.common_name}
                {!acct.Active && ' (Inactive)'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Date Range */}
        <FormControl variant="outlined" sx={{ minWidth: 150 }}>
          <InputLabel>Date Range</InputLabel>
          <Select
            value={daysFilter}
            label="Date Range"
            onChange={(e) => {
              const val = e.target.value as DateFilterType;
              setDaysFilter(val);
            }}
          >
            <MenuItem value="30">Last 30 Days</MenuItem>
            <MenuItem value="90">Last 90 Days</MenuItem>
            <MenuItem value="360">Last 360 Days</MenuItem>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
        </FormControl>

        {/* Custom Range Calendar if user picks custom */}
        {daysFilter === 'custom' && (
          <Box>
            <IconButton onClick={handleCalendarOpen}>
              <CalendarTodayIcon />
              {customDateRange.from && customDateRange.to && (
                <Typography sx={{ ml: 1 }}>
                  {formatDayRange(customDateRange.from)} - {formatDayRange(customDateRange.to)}
                </Typography>
              )}
            </IconButton>
            <Popover
              open={calendarOpen}
              anchorEl={calendarAnchorEl}
              onClose={handleCalendarClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
              <Box sx={{ p: 2 }}>
                <Calendar
                  value={customDateRange}
                  onChange={(range) => {
                    setCustomDateRange(range);
                    if (range.from && range.to) {
                      handleCalendarClose();
                    }
                  }}
                  colorPrimary="#0fbcf9"
                  colorPrimaryLight="#d1ecf1"
                  shouldHighlightWeekends
                />
              </Box>
            </Popover>
          </Box>
        )}

        {/* Show Inactive Switch */}
        <FormControlLabel
          label="Show Inactive"
          control={
            <Switch
              checked={showInactive}
              onChange={(e) => setShowInactive(e.target.checked)}
              color="primary"
            />
          }
          sx={{ marginLeft: 'auto' }}
        />
      </Box>

      {/* If no account chosen yet */}
      {!selectedAccount && (
        <Box sx={{ mt: 4 }}>
          <Typography>Please select an account to load exceptions data.</Typography>
        </Box>
      )}

      {/* If account selected, show Info + Charts */}
      {selectedAccount && (
        <>
          {/* Info Row */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 2,
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                Account Information
              </Typography>
              <Typography variant="body2">
                <strong>Device Count:</strong> {deviceCount || 'N/A'}
              </Typography>
              <Typography variant="body2">
                <strong>Active Dates:</strong> {formattedActiveFrom} - {formattedActiveTo}
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Typography variant="body2" sx={{ textAlign: 'right' }}>
                <strong>Program Total Miles Protected:</strong>{' '}
                {new Intl.NumberFormat('en-US').format(totalMiles)}
              </Typography>
            </Box>
          </Box>

          {/* Charts */}
          {isLoadingCharts ? (
            <Box textAlign="center" sx={{ mt: 6 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: {
                  xs: '1fr',
                  sm: '1fr 1fr',
                },
                paddingBottom: 3
              }}
            >
              <ExceptionsBarChart title="Speeding / 1000mi" data={chartDataSpeeding} />
              <ExceptionsBarChart title="Harsh Braking / 1000mi" data={chartDataBraking} />
              <ExceptionsBarChart title="Harsh Acceleration / 1000mi" data={chartDataAcceleration} />
              <ExceptionsBarChart title="Harsh Cornering / 1000mi" data={chartDataCornering} />
              <ExceptionsBarChart title="Seatbelt / 1000mi" data={chartDataSeatbelt} />
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

/** Single chart component */
interface ExceptionsBarChartProps {
  title: string;
  data: ChartDataPoint[];
}

function ExceptionsBarChart({ title, data }: ExceptionsBarChartProps) {
  const trendData = computeTrendLine(data);

  return (
    <Box
      sx={{
        width: 550,
        height: 320,
        border: '1px solid #ccc',
        borderRadius: 1,
        p: 1,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height="85%">
        <ComposedChart data={trendData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="label"
            type="category"
            tick={{ fontSize: 10 }}
            angle={-45}
            textAnchor="end"
          />
          <YAxis domain={[0, 'auto']} />
          <RechartsTooltip content={<CustomTooltip />} />
          <Bar dataKey="count" fill="#3E6ED0" />
          <Line
            type="monotone"
            dataKey="trend"
            stroke="#3E6ED0"
            strokeWidth={2}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

/** Mapping Functions **/
function mapDailyDataToChartData(dailyData: ExceptionWithMiles[]): ChartDataPoint[] {
  return dailyData.map((d, index) => ({
    index: index + 1,
    label: format(parseISO(d.name), 'MMM dd'),
    tooltipLabel: format(parseISO(d.name), 'MMM dd'),
    count: d.miles > 0 ? +(d.count / d.miles * 1000).toFixed(2) : 0,
  }));
}

/**
 * chunkAveragedWithMilesLabels:
 *  - Takes sorted daily data with exceptions and miles.
 *  - Splits into consecutive blocks of length chunkSize.
 *  - Each bar is (sum of exceptions in chunk / sum of miles in chunk) * 1000,
 *    with a date range label like "Jul 01 - Jul 07" or "Jul" based on labelFormat.
 */
function chunkAveragedWithMilesLabels(
  dailyData: ExceptionWithMiles[],
  chunkSize: number,
  labelFormat?: string
): ChartDataPoint[] {
  const result: ChartDataPoint[] = [];
  const totalDays = dailyData.length;
  const fullChunks = Math.floor(totalDays / chunkSize);
  const extraDays = totalDays % chunkSize;

  let dayIndex = 0;

  // Process full chunks
  for (let blockIndex = 0; blockIndex < fullChunks; blockIndex++) {
    let sumCounts = 0;
    let sumMiles = 0;

    const chunkStartIndex = dayIndex;
    for (let i = 0; i < chunkSize; i++) {
      if (dayIndex < dailyData.length) {
        sumCounts += dailyData[dayIndex].count;
        sumMiles += dailyData[dayIndex].miles;
        dayIndex++;
      }
    }

    if (sumMiles > 0) {
      const avg = (sumCounts / sumMiles) * 1000;

      const startDayStr = dailyData[chunkStartIndex].name; // "yyyy-MM-dd"
      const endDayStr = dailyData[dayIndex - 1].name;

      // Parse the dates
      const startDateObj = parseISO(startDayStr);
      const endDateObj = parseISO(endDayStr);

      let label = '';
      let tooltipLabel = '';
      if (labelFormat) {
        // Use the provided label format for X-axis
        label = format(startDateObj, labelFormat); // e.g., "Jan"
        // Tooltip shows full range
        tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
      } else {
        // Default label format
        const startLabel = format(startDateObj, 'MMM dd');
        const endLabel = format(endDateObj, 'MMM dd');
        label = `${startLabel} - ${endLabel}`;
        tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
      }

      result.push({
        index: result.length + 1,
        label,
        tooltipLabel,
        count: +avg.toFixed(2),
      });
    }
  }

  // Handle extra days
  if (extraDays > 0) {
    const threshold = Math.floor(chunkSize / 2);
    if (extraDays > threshold) {
      // Create a new bar for the extra days
      let sumCounts = 0;
      let sumMiles = 0;
      const chunkStartIndex = dayIndex;
      for (let i = 0; i < extraDays; i++) {
        if (dayIndex < dailyData.length) {
          sumCounts += dailyData[dayIndex].count;
          sumMiles += dailyData[dayIndex].miles;
          dayIndex++;
        }
      }

      if (sumMiles > 0) {
        const avg = (sumCounts / sumMiles) * 1000;

        const startDayStr = dailyData[chunkStartIndex].name; // "yyyy-MM-dd"
        const endDayStr = dailyData[dayIndex - 1].name;

        // Parse the dates
        const startDateObj = parseISO(startDayStr);
        const endDateObj = parseISO(endDayStr);

        let label = '';
        let tooltipLabel = '';
        if (labelFormat) {
          // Use the provided label format for X-axis
          label = format(startDateObj, labelFormat); // e.g., "Jan"
          // Tooltip shows full range
          tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
        } else {
          // Default label format
          const startLabel = format(startDateObj, 'MMM dd');
          const endLabel = format(endDateObj, 'MMM dd');
          label = `${startLabel} - ${endLabel}`;
          tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
        }

        result.push({
          index: result.length + 1,
          label,
          tooltipLabel,
          count: +avg.toFixed(2),
        });
      }
    } else {
      // Merge extra days into the last full chunk
      if (result.length === 0) {
        // If there are no full chunks, treat all days as one chunk
        let sumCounts = 0;
        let sumMiles = 0;
        const chunkStartIndex = dayIndex;
        for (let i = 0; i < extraDays; i++) {
          if (dayIndex < dailyData.length) {
            sumCounts += dailyData[dayIndex].count;
            sumMiles += dailyData[dayIndex].miles;
            dayIndex++;
          }
        }

        if (sumMiles > 0) {
          const avg = (sumCounts / sumMiles) * 1000;

          const startDayStr = dailyData[chunkStartIndex].name; // "yyyy-MM-dd"
          const endDayStr = dailyData[dayIndex - 1].name;

          // Parse the dates
          const startDateObj = parseISO(startDayStr);
          const endDateObj = parseISO(endDayStr);

          let label = '';
          let tooltipLabel = '';
          if (labelFormat) {
            // Use the provided label format for X-axis
            label = format(startDateObj, labelFormat); // e.g., "Jan"
            // Tooltip shows full range
            tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
          } else {
            // Default label format
            const startLabel = format(startDateObj, 'MMM dd');
            const endLabel = format(endDateObj, 'MMM dd');
            label = `${startLabel} - ${endLabel}`;
            tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
          }

          result.push({
            index: result.length + 1,
            label,
            tooltipLabel,
            count: +avg.toFixed(2),
          });
        }
      } else {
        // Merge into the last chunk
        const lastBar = result[result.length - 1];
        const lastBarIndex = lastBar.index - 1;
        const lastBarData = dailyData.slice(lastBarIndex * chunkSize, (lastBarIndex + 1) * chunkSize + extraDays);

        let sumCounts = 0;
        let sumMiles = 0;
        lastBarData.forEach((d) => {
          sumCounts += d.count;
          sumMiles += d.miles;
        });

        if (sumMiles > 0) {
          const avg = (sumCounts / sumMiles) * 1000;

          const startDayStr = dailyData[lastBarIndex * chunkSize].name;
          const endDayStr = dailyData[dailyData.length - 1].name;

          // Parse the dates
          const startDateObj = parseISO(startDayStr);
          const endDateObj = parseISO(endDayStr);

          let label = '';
          let tooltipLabel = '';
          if (labelFormat) {
            label = format(startDateObj, labelFormat);
            tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
          } else {
            const startLabel = format(startDateObj, 'MMM dd');
            const endLabel = format(endDateObj, 'MMM dd');
            label = `${startLabel} - ${endLabel}`;
            tooltipLabel = `${format(startDateObj, 'MMM dd')} - ${format(endDateObj, 'MMM dd')}`;
          }

          // Update the last bar
          result[result.length - 1] = {
            index: lastBar.index,
            label,
            tooltipLabel,
            count: +avg.toFixed(2),
          };
        }
      }
    }
  }

  return result;
}

/** Compute Trend Line **/
function computeTrendLine(data: ChartDataPoint[]): ChartDataPoint[] {
  if (!data || data.length < 2) return data;

  let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;
  data.forEach((d, i) => {
    sumX += i;
    sumY += d.count;
    sumXY += i * d.count;
    sumX2 += i * i;
  });

  const n = data.length;
  const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  // Return a new array with "trend"
  return data.map((d, i) => {
    // predicted value
    const trendVal = slope * i + intercept;
    // clamp below zero
    return {
      ...d,
      trend: trendVal < 0 ? 0 : trendVal
    };
  });
}


/**
 * groupDataWithMilesForCustomRange:
 *   - Determines label format based on total number of days.
 *   - If <= 30 days, use daily labels.
 *   - If <= 90 days, use weekly lumps.
 *   - If > 90 days, use monthly lumps with 'MMM' labels.
 *   Each bar is (sum exceptions / sum miles) * 1000, labeled accordingly.
 */
function groupDataWithMilesForCustomRange(
  dailyData: ExceptionWithMiles[],
  fromDate: Date | null,
  toDate: Date | null
): ChartDataPoint[] {
  if (!fromDate || !toDate) {
    return [];
  }

  const days = differenceInCalendarDays(toDate, fromDate) + 1;

  if (days <= 30) {
    // **Use Daily Data for ≤ 30 Days**
    return mapDailyDataToChartData(dailyData);
  } else if (days <= 90) {
    // **Use Enhanced Chunking for 31-90 Days (Weekly Chunks)**
    return chunkAveragedWithMilesLabels(dailyData, 7);
  } else {
    // **Use Enhanced Chunking for > 90 Days (Monthly Chunks with 'MMM' Labels)**
    return chunkAveragedWithMilesLabels(dailyData, 30, 'MMM');
  }
}

export default ExceptionsDashboard;
