import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { format } from 'date-fns';
import { DataPoint } from '../../types';
import ExcelDownloadButton from '../../ExcelDownloadButton';

const colors = ['#8884d8', '#82ca9d', '#FF8042', '#8A2BE2', '#FF7F50'];

const HANOVER_FLEETS_COLOR = '#2B7CD3';

interface ChartComponentProps {
  data: DataPoint[];
  regionCustomersData: Record<string, { name: string; data: DataPoint[] }>;
  selectedCustomersData: Record<string, { name: string; data: DataPoint[] }>;
  title: string;
  startDate: Date;
  endDate: Date;
}

interface CombinedDataPoint {
  date: number;
  collisionRisk?: number | null;
  benchmarkRisk?: number | null;
  bestInClassRisk?: number | null;
  [key: string]: number | null | undefined;
}

type LegendType =
  | 'line'
  | 'square'
  | 'rect'
  | 'circle'
  | 'cross'
  | 'diamond'
  | 'star'
  | 'triangle'
  | 'wye'
  | 'none';

interface LegendPayload {
  value: string;
  type?: LegendType;
  color: string;
}

interface PercentChangeInfo {
  percentChange: number | null;
  changeDirection: 'increase' | 'decrease' | null;
}

const HANOVER_FLEETS_NAME = 'Hanover Fleets';
const BENCHMARK_RISK_NAME = 'Benchmark Risk';
const BEST_IN_CLASS_RISK_NAME = 'Best in Class Risk';

const lineDescriptions: Record<string, string> = {
  [HANOVER_FLEETS_NAME]: 'Avg collision risk across all Hanover fleets.',
  [BENCHMARK_RISK_NAME]: 'Industry benchmark collision risk.',
  [BEST_IN_CLASS_RISK_NAME]: 'Collision risk for top-performing fleets.',
};

function formatTickLabel(dateNum: number): string {
  return format(new Date(dateNum), 'MMM dd');
}

function calculateTicks(minDate: number, maxDate: number, maxTicks: number): number[] {
  const dayMs = 24 * 60 * 60 * 1000;
  const dateRangeInDays = Math.round((maxDate - minDate) / dayMs) + 1;

  const ticks: number[] = [];
  if (dateRangeInDays <= maxTicks) {
    // daily ticks
    for (let i = 0; i < dateRangeInDays; i++) {
      ticks.push(minDate + i * dayMs);
    }
  } else {
    const interval = (maxDate - minDate) / (maxTicks - 1);
    for (let i = 0; i < maxTicks; i++) {
      ticks.push(Math.round(minDate + i * interval));
    }
  }
  return ticks;
}

const ChartComponent: React.FC<ChartComponentProps> = ({
  data,
  regionCustomersData,
  selectedCustomersData,
  title,
  startDate,
  endDate,
}) => {
  const [legendHeight, setLegendHeight] = useState(0);

  // Decide which set of customers to use for the Excel export columns:
  // 1) If user has selected at least 1 => use `selectedCustomersData`
  // 2) Otherwise => use region-limited `regionCustomersData`
  const hasSelections = Object.keys(selectedCustomersData).length > 0;
  const excelCustomersData = hasSelections ? selectedCustomersData : regionCustomersData;

  // Prepare data for Excel download
  const prepareExcelData = useCallback(() => {
    // We keep aggregator columns (Collision Risk, Benchmark Risk),
    // but only add customer columns from excelCustomersData
    return data.map((dp) => {
      return {
        'Date': format(new Date(dp.date), 'yyyy-MM-dd'),
        'Collision Risk': dp.collisionRisk ?? '',
        // We'll add each relevant customer's risk below
        // in the .forEach after we create this row object
      };
    }).map((baseRow) => {
      // Because we want to fill in each row with the selected/region-limited customers
      const rowCopy = { ...baseRow } as Record<string, string | number>;
      const dateNumber = new Date(baseRow.Date).getTime();

      // For each region/selected customer, see if they have data for this date
      Object.entries(excelCustomersData).forEach(([_, custObj]) => {
        const match = custObj.data.find((dp) => dp.date === dateNumber);
        rowCopy[custObj.name] = match?.collisionRisk ?? '';
      });
      return rowCopy;
    });
  }, [data, excelCustomersData]);

  const excelData = useMemo(() => prepareExcelData(), [prepareExcelData]);

  // =======================
  // 1) Build combined chart data (aggregator + selected customers)
  const [combinedChartData, setCombinedChartData] = useState<CombinedDataPoint[]>([]);
  useEffect(() => {
    const dateMap: Record<number, CombinedDataPoint> = {};

    // Start with aggregator
    data.forEach((pt) => {
      if (!dateMap[pt.date]) {
        dateMap[pt.date] = { date: pt.date };
      }
      dateMap[pt.date].collisionRisk = pt.collisionRisk || null;
    });

    // Add each selected customer's data
    Object.entries(selectedCustomersData).forEach(([custId, { data: arr }]) => {
      const dataKey = `customer_${custId}`;
      arr.forEach((pt) => {
        if (!dateMap[pt.date]) {
          dateMap[pt.date] = { date: pt.date };
        }
        dateMap[pt.date][dataKey] = pt.collisionRisk ?? null;
      });
    });

    const finalArray = Object.values(dateMap)
      // remove any date that has no aggregator or no selected customers
      .filter((entry) => {
        // We'll keep it if aggregator has a value OR any selected customer has a value
        const keys = Object.keys(entry).filter((k) => k !== 'date');
        return keys.some((k) => entry[k] != null);
      })
      .sort((a, b) => a.date - b.date);

    setCombinedChartData(finalArray);
  }, [data, selectedCustomersData]);

  // 2) X-axis ticks
  const minDate = combinedChartData.length
    ? Math.min(...combinedChartData.map((d) => d.date))
    : startDate.getTime();
  const maxDate = combinedChartData.length
    ? Math.max(...combinedChartData.map((d) => d.date))
    : endDate.getTime();

  const dataPointCount = combinedChartData.length;
  const maxTicks = dataPointCount <= 10 ? dataPointCount : 6;
  const ticks = calculateTicks(minDate, maxDate, maxTicks);

  // 3) Build custom Legend payload
  const [visibleLines, setVisibleLines] = useState<Record<string, boolean>>({
    [HANOVER_FLEETS_NAME]: true,
    [BEST_IN_CLASS_RISK_NAME]: true,
  });

  // If new customers are selected, ensure they're visible
  useEffect(() => {
    setVisibleLines((prev) => {
      const updated = { ...prev };
      // Always keep aggregator lines in the object
      if (!(HANOVER_FLEETS_NAME in updated)) {
        updated[HANOVER_FLEETS_NAME] = true;
      }
      if (!(BEST_IN_CLASS_RISK_NAME in updated)) {
        updated[BEST_IN_CLASS_RISK_NAME] = true;
      }
      // Add each selected customer line
      Object.values(selectedCustomersData).forEach(({ name }) => {
        if (!(name in updated)) {
          updated[name] = true;
        }
      });
      return updated;
    });
  }, [selectedCustomersData]);

  const legendPayload: LegendPayload[] = useMemo(() => {
    let idx = 0;
    const payload: LegendPayload[] = [
      { value: HANOVER_FLEETS_NAME, type: 'line', color: HANOVER_FLEETS_COLOR },
    ];
    // Add each selected customer
    Object.values(selectedCustomersData).forEach(({ name }) => {
      payload.push({
        value: name,
        type: 'line',
        color: colors[idx % colors.length],
      });
      idx++;
    });
    return payload;
  }, [selectedCustomersData]);

  const handleLegendClick = (legendItem: { value: string }) => {
    const { value } = legendItem;
    setVisibleLines((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  // For the tooltip to re-map dataKey => correct displayName
  const customerDataKeys = useMemo(() => {
    const map: Record<string, string> = {};
    Object.entries(selectedCustomersData).forEach(([custId, { name }]) => {
      map[`customer_${custId}`] = name;
    });
    return map;
  }, [selectedCustomersData]);

  // 4) Percent-change logic
  const [percentChanges, setPercentChanges] = useState<Record<string, PercentChangeInfo>>({});
  useEffect(() => {
    const linesToCheck = [
      { dataKey: 'collisionRisk', name: HANOVER_FLEETS_NAME },
      // You might have something for bestInClassRisk if you ever set it
      ...Object.entries(selectedCustomersData).map(([custId, { name }]) => ({
        dataKey: `customer_${custId}`,
        name,
      })),
    ];

    const newChanges: Record<string, PercentChangeInfo> = {};
    linesToCheck.forEach(({ dataKey, name }) => {
      const values = combinedChartData
        .map((pt) => pt[dataKey])
        .filter((val): val is number => val != null);
      if (values.length < 2) {
        newChanges[name] = { percentChange: null, changeDirection: null };
      } else {
        const first = values[0];
        const last = values[values.length - 1];
        const change = last - first;
        const pct = (change / first) * 100;
        let dir: 'increase' | 'decrease' | null = null;
        if (pct > 0) dir = 'increase';
        else if (pct < 0) dir = 'decrease';
        newChanges[name] = {
          percentChange: Math.abs(pct),
          changeDirection: dir,
        };
      }
    });
    setPercentChanges(newChanges);
  }, [combinedChartData, selectedCustomersData]);

  return (
    <Box sx={{ marginTop: 4 }}>
      {/* Title & Export */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" gutterBottom sx={{ marginRight: 1 }}>
            {title}
            <Tooltip
              title="Probability of a collision occurring in the next 100k miles, for your date range. Aggregator = global fleets."
              arrow
            >
              <InfoOutlinedIcon color="action" fontSize="inherit" />
            </Tooltip>
          </Typography>
        </Box>

        {/* Download button -> excelData is region-limited or selected */}
        <ExcelDownloadButton data={excelData} filename="collision_risk_data.xlsx" />
      </Box>

      {/* Chart */}
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={combinedChartData}
          margin={{ top: legendHeight, right: 30, left: 20, bottom: 80 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            type="number"
            domain={[minDate, maxDate]}
            ticks={ticks}
            tickFormatter={(val) => formatTickLabel(val)}
            angle={-45}
            textAnchor="end"
            height={70}
            scale="time"
          />
          <YAxis
            tickFormatter={(val) => `${val}%`}
            label={{
              value: 'Avg Collision Risk',
              angle: -90,
              position: 'insideLeft',
              dx: -10,
              style: { textAnchor: 'middle' }, // Ensures proper centering
            }}
          />
          <RechartsTooltip
            labelFormatter={(labelNum) => format(new Date(labelNum), 'PPP')}
            formatter={(value: any, name: string, props: any) => {
              const { dataKey } = props;
              let displayName = customerDataKeys[dataKey] || name;
              if (dataKey === 'collisionRisk') displayName = HANOVER_FLEETS_NAME;

              if (value == null) {
                return ['No data', displayName];
              }

              const pc = percentChanges[displayName];
              let pctText = '';
              if (pc && pc.percentChange != null) {
                pctText = ` (${pc.changeDirection === 'increase' ? '+' : '-'}${pc.percentChange.toFixed(1)}%)`;
              }
              return [`${value}%`, `${displayName}${pctText}`];
            }}
          />
          <Legend
            wrapperStyle={{ width: '100%', paddingBottom: 20 }}
            verticalAlign="top"
            align="center"
            onClick={handleLegendClick}
            payload={legendPayload}
            onBBoxUpdate={(box) => setLegendHeight((box?.height ?? 0) + 20)}
            formatter={(value) => {
              const active = visibleLines[value];
              const hasDescription = lineDescriptions[value];
              const pc = percentChanges[value];
              let pctText = '';
              if (pc && pc.percentChange != null) {
                pctText = ` (${pc.changeDirection === 'increase' ? '+' : '-'}${pc.percentChange.toFixed(1)}%)`;
              }

              const label = (
                <span
                  style={{
                    textDecoration: active ? 'none' : 'line-through',
                    color: active ? '#000' : '#AAA',
                    cursor: 'pointer',
                  }}
                >
                  {value}{pctText}
                </span>
              );
              return hasDescription ? (
                <Tooltip title={hasDescription} arrow>
                  {label}
                </Tooltip>
              ) : (
                label
              );
            }}
          />

          {/* Aggregator line */}
          <Line
            type="monotone"
            dataKey="collisionRisk"
            stroke={HANOVER_FLEETS_COLOR}
            strokeWidth={2}
            dot={false}
            name={HANOVER_FLEETS_NAME}
            hide={!visibleLines[HANOVER_FLEETS_NAME]}
            connectNulls
          />

          {/* Best in Class */}
          {/* <Line
            type="monotone"
            dataKey="bestInClassRisk"
            stroke={BEST_IN_CLASS_COLOR}
            strokeWidth={2}
            dot={false}
            name={BEST_IN_CLASS_RISK_NAME}
            hide={!visibleLines[BEST_IN_CLASS_RISK_NAME]}
            connectNulls
          /> */}

          {/* Each selected customer */}
          {Object.entries(selectedCustomersData).map(([custId, custInfo], index) => {
            const dataKey = `customer_${custId}`;
            return (
              <Line
                key={dataKey}
                type="monotone"
                dataKey={dataKey}
                stroke={colors[index % colors.length]}
                strokeWidth={2}
                dot={false}
                name={custInfo.name}
                hide={!visibleLines[custInfo.name]}
                connectNulls
              />
            );
          })}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default React.memo(ChartComponent);
