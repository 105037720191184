import React, { useEffect, useState, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Toolbar,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableSortLabel,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import { User, Region } from '../types';
import { AppContext } from '../../AppContext';
import { useAuth0 } from '@auth0/auth0-react';
import { getComparator, Order } from '../utils';

const UsersPage: React.FC = () => {
  const { user: auth0User } = useAuth0();
  const [users, setUsers] = useState<User[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);
  const [deleteUserSub, setDeleteUserSub] = useState<string | undefined>(undefined);
  const [deleteUserEmail, setDeleteUserEmail] = useState<string>('');
  const { user: currentUser } = useContext(AppContext);

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof User>('email'); // Default sorting column

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const regionId = currentUser?.region_id;
        getUsers(regionId).then((response) => {
          setUsers(response.data);
        });
      } catch (error) {
        console.error('Failed to fetch users', error);
      }
    };

    const fetchRegions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/regions`);
        setRegions(response.data);
      } catch (error) {
        console.error('Failed to fetch regions', error);
      }
    };

    fetchUsers();
    fetchRegions();
  }, [currentUser?.region_id]);

  const handleAddUser = async (email: string, password: string, role_id: number, region_id: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/add-user`, {
        email,
        password,
        role_id,
        region_id,
        auth0_user_sub: auth0User?.sub,
      });

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to add user', error);
    }
  };

  const handleEditClick = (user: User) => {
    setEditingUser(user);
    setOpenEditUserModal(true);
  };

  const getUsers = async (region_id: any) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/users`,
      {
        params: { region_id: region_id }
      }
    );
    return response;
  }

  const handleUpdateUser = async (updatedUser: User) => {
    getUsers(updatedUser.region_id).then((response) => {
      setUsers(response.data);
    });
    setOpenEditUserModal(false);
    setEditingUser(null);
  };

  const handleDeleteClick = (user: User) => {
    setDeleteUserId(user.id);
    setDeleteUserSub(user.auth0_sub);
    setDeleteUserEmail(user.email);
  };

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/delete-user`, {
        data: { user_id: deleteUserId, auth0_sub: deleteUserSub, current_auth0_sub: auth0User?.sub },
      });

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
      setUsers(response.data);
      setDeleteUserId(null);
      setDeleteUserEmail('');
    } catch (error) {
      console.error('Failed to delete user', error);
    }
  };

  const handleCancelDelete = () => {
    setDeleteUserId(null);
    setDeleteUserEmail('');
  };

  const handleRequestSort = (property: keyof User) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedUsers = users.slice().sort(getComparator(order, orderBy));

  return (
    <Container maxWidth="xl">
      <Toolbar />
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenAddUserModal(true)}
        style={{ marginBottom: 20 }}
      >
        Add User
      </Button>
      <div style={{ maxHeight: '500px', overflow: 'auto' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'email'}
                    direction={orderBy === 'email' ? order : 'asc'}
                    onClick={() => handleRequestSort('email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Region</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedUsers.map((user) => {
                const canEditOrDelete = user.role_id >= (currentUser ? currentUser.role_id : 3);

                return (
                  <TableRow key={user.id}>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {user.role_id === 1 ? 'SUPERADMIN' : user.role_id === 2 ? 'ADMIN' : 'MEMBER'}
                    </TableCell>
                    <TableCell>{user.region || 'No Region'}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleEditClick(user)}
                        disabled={!canEditOrDelete}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteClick(user)}
                        disabled={!canEditOrDelete}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Dialog open={Boolean(deleteUserId)} onClose={handleCancelDelete}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete user {deleteUserEmail}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AddUserModal
        open={openAddUserModal}
        onClose={() => setOpenAddUserModal(false)}
        onAddUser={handleAddUser}
        regions={regions}
      />

      {editingUser && (
        <EditUserModal
          open={openEditUserModal}
          onClose={() => {
            setOpenEditUserModal(false);
            setEditingUser(null);
          }}
          user={editingUser}
          onUpdateUser={handleUpdateUser}
          regions={regions}
        />
      )}
    </Container>
  );
};

export default UsersPage;
