// AppContext.tsx
import React, { createContext, useState } from 'react';

interface AppContextProps {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isDashboardRestricted: boolean;
  setIsDashboardRestricted: (value: boolean) => void;
  user: User | null;
  setUser: (user: User | null) => void;
}

export interface User {
  id: string;
  email: string;
  role_id: number;
  region_id?: string | number;
  permissions?: string[];
}

export const AppContext = createContext<AppContextProps>({
  isAuthenticated: false,
  setIsAuthenticated: () => { },
  isDashboardRestricted: false,
  setIsDashboardRestricted: () => { },
  user: null,
  setUser: () => { },
});

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDashboardRestricted, setIsDashboardRestricted] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        isDashboardRestricted,
        setIsDashboardRestricted,
        user,
        setUser,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
