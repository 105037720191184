// AddUserModal.tsx
import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  MenuItem,
} from '@mui/material';
import { Region } from '../types';
import { AppContext } from '../../AppContext';

interface AddUserModalProps {
  open: boolean;
  onClose: () => void;
  onAddUser: (email: string, password: string, roleId: number, regionId: string) => void;
  regions: Region[];
}

const AddUserModal: React.FC<AddUserModalProps> = ({ open, onClose, onAddUser, regions }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roleId, setRoleId] = useState('');
  const [regionId, setRegionId] = useState('');
  const { user: currentUser } = useContext(AppContext);

  const handleSubmit = () => {
    onAddUser(email, password, Number(roleId), regionId);
    setEmail('');
    setPassword('');
    setRoleId('');
    setRegionId('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          label="Role"
          fullWidth
          select
          value={roleId}
          onChange={(e) => setRoleId(e.target.value)}
          margin="dense"
        >
          {/* Only show SUPERADMIN if currentUserRole is 1 */}
          {currentUser ? currentUser.role_id <= 1 && <MenuItem value="1">SUPERADMIN</MenuItem> : <div/>}

          {/* Only show ADMIN if currentUserRole <= 2 */}
          {currentUser ? currentUser.role_id <= 2 && <MenuItem value="2">ADMIN</MenuItem> : <div/>}

          {/* Always show MEMBER, since it’s role=3 and is “lower” */}
          <MenuItem value="3">MEMBER</MenuItem>
        </TextField>
        <TextField
          label="Region"
          fullWidth
          select
          value={regionId}
          onChange={(e) => setRegionId(e.target.value as string)}
          margin="dense"
        >
          {regions.map((region) => (
            <MenuItem key={region.id} value={region.id}>
              {region.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Add User
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserModal;
